import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
// import Get from "../../Get";

function ArticulosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;

  const [codigo, setCodigo] = useState("");
  const [codigoSAT, setCodigoSAT] = useState("11161800");
  const [codigoUnidad, setCodigoUnidad] = useState("MTR");
  const [nombre, setNombre] = useState("");
  const [composicion, setComposicion] = useState("NA");
  const [descripcion, setDescripcion] = useState("");
  const [unidad, setUnidad] = useState("");
  const [ancho, setAncho] = useState(1);
  const [peso, setPeso] = useState(1);
  const [rendimiento, setRendimiento] = useState(1);
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [tipos_telas, setTiposTelas] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [selectedTipoTela, setSelectedTipoTela] = useState("");
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [costo, setCosto] = useState(0);
  const [venta, setVenta] = useState(0);

  const [modalColores, setModalColores] = useState(false);
  const toggleColores = () => setModalColores(!modalColores);
  const [arrayColores, setArrayColores] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_TIPOS_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTiposTelas = res.data;
        setTiposTelas(allTiposTelas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_COLECCIONES, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allColecciones = res.data;
          setColecciones(allColecciones);
        })
        .catch((err) => {
          console.log(err);
        });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function coloresSeleccionados(checked, idColor) {
    if (checked) {
      arrayColores.push(idColor)
    }
    else{
      const index = arrayColores.indexOf(idColor);
      if (index > -1) {
        arrayColores.splice(index, 1)
      }
    }
  }


  const saveArticulos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_ARTICULOS,
          {
            // codigo,
            codigoSAT,
            codigoUnidad,
            nombre,
            composicion,
            descripcion,
            unidad,
            ancho,
            peso,
            rendimiento,
            costo,
            venta,
            tiposTelas: selectedTipoTela,
            colecciones: selectedColeccion,
            observaciones,
            colores:arrayColores
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )   .then(() => {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Articulo",
                      detalle: `${nombre}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true)
                });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function coloresInfo(){
    toggleColores()
  }

  console.log(arrayColores)

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card container">
          <h3 align="center">Nuevo Articulo</h3>
          {/* <Get/> */}
          <Form onSubmit={saveArticulos}>
            <Row>
            {/* <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col> */}
              <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              
              <Col md={3}>
                <Label>Composicion</Label>
                <Input
                  type="text"
                  placeholder="Composicion"
                  value={composicion}
                  required
                  onChange={(e) => {
                    setComposicion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Ancho</Label>
                <Input
                  type="number"
                  placeholder="Ancho"
                  value={ancho}
                  required
                  onChange={(e) => {
                    setAncho(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Coleccion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColeccion}
                  required
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>

            <Row>
            <Col md={3}>
                <Label>Codigo SAT</Label>
                <Input
                  type="number"
                  placeholder="Codigo SAT"
                  value={codigoSAT}
                  required
                  onChange={(e) => {
                    setCodigoSAT(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Descripcion</Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Unidad</Label>
                <Input
                  type="select"
                  placeholder="Unidad"
                  value={unidad}
                  required
                  onChange={(e) => {
                    setUnidad(e.target.value);
                  }}
                >
                  <option value="0">Unidad</option>
                  <option value="Metros">Metros</option>
                  <option value="Kilos">Kilos</option>
                </Input>
              </Col>
              <Col md={3}>
                <Label>Peso por m2</Label>
                <Input
                  type="number"
                  placeholder="Peso por m2"
                  value={peso}
                  required
                  onChange={(e) => {
                    setPeso(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Rendimiento</Label>
                <Input
                  type="number"
                  placeholder="Rendimiento"
                  value={rendimiento}
                  required
                  onChange={(e) => {
                    setRendimiento(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Costo</Label>
                <Input
                  type="number"
                  placeholder="Costo"
                  value={costo}
                  required
                  onChange={(e) => {
                    setCosto(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Venta</Label>
                <Input
                  type="number"
                  placeholder="Venta"
                  value={venta}
                  required
                  onChange={(e) => {
                    setVenta(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Tipo de Tela</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedTipoTela}
                  required
                  onChange={(e) => {
                    setSelectedTipoTela(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Tipo de Tela</option>
                  {tipos_telas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
             
             
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Agregar Colores
                </Label>
                <br />
              <Button
                color="dany"
                id="Colores"
                onClick={(e) => coloresInfo()}
              >
                <i class="fas fa-palette fa-3x"></i>
              </Button>
              </Col>
              <Col md={3}>
                <Label>Codigo Unidad SAT</Label>
                <Input
                  type="text"
                  placeholder="Codigo Unidad"
                  value={codigoUnidad}
                  required
                  onChange={(e) => {
                    setCodigoUnidad(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />

          <Modal size="lg" isOpen={modalColores} toggle={toggleColores}>
        <ModalHeader toggle={toggleColores}>
          <h4>
            Colores del articulo {nombre} 
          </h4>
        </ModalHeader>
        <ModalBody>
        {colores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a, index)=>{
                        if(!arrayColores.includes(a._id)){
                          return(
                            <table>
                              <td>
                            <Input
                            type="checkbox"
                            key={index}
                            onChange ={(e)=>{coloresSeleccionados(e.currentTarget.checked, a._id)}}
                            className="subMenu"
                          /><h5 className="subMenuTitulo">{a.name}</h5>
                          </td>
                          </table>
                          )}else{return<h5 className="subMenuTitulo">{a.name}</h5>}
                    })}
        </ModalBody>
      </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosCreate;
