import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from 'moment';

function AntiguedadSaldosVencido() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [formaPago, setFormaPago] = useState("");
  const [concepto, setConcepto] = useState("");



  const [text, setText] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  const [totalEnTiempoGeneral, setTotalEnTiempoGeneral] = useState(0);
  const [totalSaldoGeneral, setTotalSaldoGeneral] = useState(0);
  const [totalTreintaGeneral, setTotalTreintaGeneral] = useState(0);
  const [totalSesentaGeneral, setTotalSesentaGeneral] = useState(0);
  const [totalNoventaGeneral, setTotalNoventaGeneral] = useState(0);
  const [totalCientoVeinteGeneral, setTotalCientoVeinteGeneral] = useState(0);
  const [totalCientoOchentaGeneral, setTotalCientoOchentaGeneral] = useState(0);
  const [totalMasGeneral, setTotalMasGeneral] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{
    axios
      .get(`${URL_DASHBOARDS}AntiguedadSaldosVencido`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        // Array para pagination
        let arrayTabla = allVentas
        .sort((a, b) => (a.nombreCliente > b.nombreCliente ? 1 : -1))
          .map((a) => {
            return {
              idCliente: a.clienteId,
              cliente: a.nombreCliente,
              saldo: a.saldo,
              enTiempo: a.enTiempo,
              treinta: a.treinta,
              sesenta: a.sesenta,
              noventa: a.noventa,
              cientoveinte: a.cientoveinte,
              cientoochenta: a.cientoochenta,
              otro: a.otro,
            }
              
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);



        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);


  function PDFTabla() {
    const data = comments
    .filter(
      (comment) =>
      comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
      comment.saldo.toString().includes(search) ||
      comment.enTiempo.toString().includes(search) ||
      comment.treinta.toString().includes(search) ||
      comment.sesenta.toString().includes(search) ||
      comment.noventa.toString().includes(search) ||
      comment.cientoveinte.toString().includes(search) ||
      comment.cientoochenta.toString().includes(search) ||
      comment.otro.toString().includes(search)
    )
    .map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return [
          a.cliente,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoveinte),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoochenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.otro),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Antiguedad Saldos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Cliente",
          "Saldo",
          "En Tiempo",
          "30",
          "60",
          "90",
          "120",
          "180",
          "+ 180",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral),
        ]
      ],
      showFoot: "lastPage",
    });
    // doc.save(`Abonos.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = comments
    .filter(
      (comment) =>
      comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
      comment.saldo.toString().includes(search) ||
      comment.enTiempo.toString().includes(search) ||
      comment.treinta.toString().includes(search) ||
      comment.sesenta.toString().includes(search) ||
      comment.noventa.toString().includes(search) ||
      comment.cientoveinte.toString().includes(search) ||
      comment.cientoochenta.toString().includes(search) ||
      comment.otro.toString().includes(search)
    )
    .map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return {
          Cliente: a.cliente,
          Saldo: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
          EnTiempo: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo),
          "30": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta),
          "60": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta),
          "90": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa),
          "120": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoveinte),
          "180": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoochenta),
          "+ 180": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.otro),
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "AntiguedadSaldosVencido";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "AntiguedadSaldosVencido",
        sheetFilter: [
          "Cliente",
          "Saldo",
          "EnTiempo",
          "30",
          "60",
          "90",
          "120",
          "180",
          "+ 180",
          ],
        sheetHeader: [
          "Cliente",
          "Saldo",
          "EnTiempo",
          "30",
          "60",
          "90",
          "120",
          "180",
          "+ 180",
          ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments
    .filter(
      (comment) =>
      comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
      comment.saldo.toString().includes(search) ||
      comment.enTiempo.toString().includes(search) ||
      comment.treinta.toString().includes(search) ||
      comment.sesenta.toString().includes(search) ||
      comment.noventa.toString().includes(search) ||
      comment.cientoveinte.toString().includes(search) ||
      comment.cientoochenta.toString().includes(search) ||
      comment.otro.toString().includes(search)
    )
    .map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return [
          a.cliente,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoveinte),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoochenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.otro),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Antiguedad Saldos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Cliente",
          "Saldo",
          "En Tiempo",
          "30",
          "60",
          "90",
          "120",
          "180",
          "+ 180",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral),
        ]
      ],
      showFoot: "lastPage",
    });
    
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Antiguedad Saldos",
          email: mailTo,
          fileName: "AntiguedadSaldosVencido.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Antiguedad de Saldos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

 

  const headers = [
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Saldo Total", field: "saldo", sortable: true },
    { name: "En Tiempo", field: "enTiempo", sortable: true },
    { name: "Vencido 30", field: "treinta", sortable: true },
    { name: "Vencido 60", field: "sesenta", sortable: true },
    { name: "Vencido 90", field: "noventa", sortable: true },
    { name: "Vencido 120", field: "cientoveinte", sortable: true },
    { name: "Vencido 180", field: "cientoochenta", sortable: true },
    { name: "Mas de 180", field: "otro", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.saldo.toString().includes(search) ||
          comment.enTiempo.toString().includes(search) ||
          comment.treinta.toString().includes(search) ||
          comment.sesenta.toString().includes(search) ||
          comment.noventa.toString().includes(search) ||
          comment.cientoveinte.toString().includes(search) ||
          comment.cientoochenta.toString().includes(search) ||
          comment.otro.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }


    setTotalItems(computedComments.length);

    let sal = computedComments.map((c) => parseFloat(c.saldo));
    let Tsal = sal.reduce((t, importe, index) => t + importe, 0);
    setTotalSaldoGeneral(Tsal);

    let ven = computedComments.map((c) => parseFloat(c.enTiempo));
      let Tven = ven.reduce((t, importe, index) => t + importe, 0);
      setTotalEnTiempoGeneral(Tven);

      let t = computedComments.map((c) => parseFloat(c.treinta));
      let Tt = t.reduce((t, importe, index) => t + importe, 0);
      setTotalTreintaGeneral(Tt);

      let s = computedComments.map((c) => parseFloat(c.sesenta));
      let Ts = s.reduce((t, importe, index) => t + importe, 0);
      setTotalSesentaGeneral(Ts);

      let n = computedComments.map((c) => parseFloat(c.noventa));
      let Tn = n.reduce((t, importe, index) => t + importe, 0);
      setTotalNoventaGeneral(Tn);

      let v = computedComments.map((c) => parseFloat(c.cientoveinte));
      let Tv = v.reduce((t, importe, index) => t + importe, 0);
      setTotalCientoVeinteGeneral(Tv);

      let o = computedComments.map((c) => parseFloat(c.cientoochenta));
      let To = o.reduce((t, importe, index) => t + importe, 0);
      setTotalCientoOchentaGeneral(To);

      let m = computedComments.map((c) => parseFloat(c.otro));
      let Tm = m.reduce((t, importe, index) => t + importe, 0);
      setTotalMasGeneral(Tm);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "enTiempo" &&
      sorting.field != "saldo" &&
      sorting.field != "treinta" &&
      sorting.field != "sesenta" &&
      sorting.field != "noventa" &&
      sorting.field != "cientoveinte" &&
      sorting.field != "cientoochenta" &&
      sorting.field != "otro"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "enTiempo" ||
        sorting.field == "saldo" ||
        sorting.field == "treinta" ||
        sorting.field == "sesenta" ||
        sorting.field == "noventa" ||
        sorting.field == "cientoveinte" ||
        sorting.field == "cientoochenta" ||
        sorting.field == "otro")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "enTiempo" ||
        sorting.field == "saldo" ||
        sorting.field == "treinta" ||
        sorting.field == "sesenta" ||
        sorting.field == "noventa" ||
        sorting.field == "cientoveinte" ||
        sorting.field == "cientoochenta" ||
        sorting.field == "otro")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
  ]);



  let totalEnTiempo = 0;
  let totalSaldo = 0;
  let totalTreinta = 0;
  let totalSesenta = 0;
  let totalNoventa = 0;
  let totalCientoVeinte = 0;
  let totalCientoOchenta = 0;
  let totalMas = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        
          <h3 align="center">Antiguedad de Saldos Vencido</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>

          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
   
            <tbody>
              {commentsData.map((a) => {
               
                  totalEnTiempo = totalEnTiempo + a.enTiempo;
                  totalSaldo = totalSaldo + a.saldo;
                  totalTreinta = totalTreinta + a.treinta;
                  totalSesenta = totalSesenta + a.sesenta;
                  totalNoventa = totalNoventa + a.noventa;
                  totalCientoVeinte = totalCientoVeinte + a.cientoveinte;
                  totalCientoOchenta = totalCientoOchenta + a.cientoochenta;
                  totalMas = totalMas + a.otro;
     
                    return (
                      <tr>
                        <td>{a.cliente}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoveinte)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoochenta)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.otro)}</td>

                      
                      </tr>
                    );
  
 
              })}
              <tr>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldo)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempo)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreinta)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesenta)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventa)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinte)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchenta)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMas)} </td>
                <td></td>
              </tr>

              <tr>
                <td className="negrita" align="center">
                  TOTAL GENERAL
                </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral)} </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
    
     
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Antiguedad Saldos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

      {loader}
    </>
  );
}

export default AntiguedadSaldosVencido;
