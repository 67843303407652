import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faFacebook, fas } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faHome,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Password from "../Login/Password";

library.add(
  fab,
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faFacebook,
  faHome,
  faEnvelope,
  faPhoneAlt
);

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);

  const [dropdownOpenArticulos, setDropdownOpenArticulos] = useState(false);
  const toggleArticulos = () => setDropdownOpenArticulos((prevState) => !prevState);

  const [dropdownOpenCompras, setDropdownOpenCompras] = useState(false);
  const toggleCompras = () => setDropdownOpenCompras((prevState) => !prevState);

  const [dropdownOpenComprasInt, setDropdownOpenComprasInt] = useState(false);
  const toggleComprasInt = () => setDropdownOpenComprasInt((prevState) => !prevState);

  const [dropdownOpenCXP, setDropdownOpenCXP] = useState(false);
  const toggleCXP = () => setDropdownOpenCXP((prevState) => !prevState);

  const [dropdownOpenVentas, setDropdownOpenVentas] = useState(false);
  const toggleVentas = () => setDropdownOpenVentas((prevState) => !prevState);

  const [dropdownOpenInventarios, setDropdownOpenInventarios] = useState(false);
  const toggleInventarios = () => setDropdownOpenInventarios((prevState) => !prevState);

  const [dropdownOpenEmpleados, setDropdownOpenEmpleados] = useState(false);
  const toggleEmpleados = () => setDropdownOpenEmpleados((prevState) => !prevState);

  const [dropdownOpenAdmin, setDropdownOpenAdmin] = useState(false);
  const toggleAdmin = () => setDropdownOpenAdmin((prevState) => !prevState);

  const [dropdownOpenFiscal, setDropdownOpenFiscal] = useState(false);
  const toggleFiscal = () => setDropdownOpenFiscal((prevState) => !prevState);

  const [dropdownOpenReportes, setDropdownOpenReportes] = useState(false);
  const toggleReportes = () => setDropdownOpenReportes((prevState) => !prevState);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);
  return (
    <>
      {isAuth ? (
        <header className="sticky-top" >
          <>
            <Navbar color="dark" dark expand="lg" >
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>

                   {user.menu_articulos ? (
              <NavItem>
              <NavLink className="titulosNav" >
                <Dropdown isOpen={dropdownOpenArticulos} toggle={toggleArticulos}>
                <DropdownToggle color="link">
                <i class="fas fa-tshirt"></i>
                <br/> ARTICULOS
              </DropdownToggle>
              <DropdownMenu>
                <h5 style={{color:"grey", paddingLeft:"7px"}}>ARTICULOS</h5>
                <DropdownItem href="/Colores">Colores</DropdownItem>
                <DropdownItem href="/TiposTelas">Tipos de Tela</DropdownItem>
                <DropdownItem href="/Colecciones">Colecciones</DropdownItem>
                <DropdownItem href="/ListadoArticulos">Articulos</DropdownItem>
              </DropdownMenu>
              </Dropdown>
              </NavLink>
              </NavItem>
                  ) : undefined}
                   
                  {/* {user.menu_compras ? (
                <NavItem>
                <NavLink className="titulosNav" >
                  <Dropdown isOpen={dropdownOpenCompras} toggle={toggleCompras}>
                  <DropdownToggle color="link">
                  <i class="fas fa-shopping-basket"></i>
                  <br/> COMPRAS
                </DropdownToggle>
                <DropdownMenu>
                <h5 style={{color:"grey", paddingLeft:"7px"}}>COMPRAS</h5>
                  <DropdownItem href="/Proveedores">Proveedores</DropdownItem>
                  <DropdownItem href="/ListadoOrdenesCompra">Ordenes de Compra</DropdownItem>
                  <DropdownItem href="/ListadoCompras">Compras</DropdownItem>
                  <DropdownItem href="/ListadoDevolucionesProveedores">Devoluciones</DropdownItem>
                </DropdownMenu>
                </Dropdown>
                </NavLink>
                </NavItem>
                  ) : undefined} */}

      {user.menu_compras ? (
                <NavItem>
                <NavLink className="titulosNav" >
                  <Dropdown isOpen={dropdownOpenComprasInt} toggle={toggleComprasInt}>
                  <DropdownToggle color="link">
                  <i class="fas fa-shopping-basket"></i>
                  <br/> COMPRAS INT
                </DropdownToggle>
                <DropdownMenu>
                <h5 style={{color:"grey", paddingLeft:"7px"}}>COMPRAS INT</h5>
                  <DropdownItem href="/ListadoOrdenesCompraInt">Ordenes de Compra</DropdownItem>
                  <DropdownItem href="/ListadoPendEmbarcar">Pendiente Embarcar</DropdownItem>
                  <DropdownItem href="/ListadoEmbarques">Embarques</DropdownItem>
                  <DropdownItem href="/ListadoPendRecibirInt">Pendiente Recibir</DropdownItem>
                  <DropdownItem href="/ListadoComprasInt">Compras</DropdownItem>
                  <DropdownItem href="/ConceptosGastosEmbarques">Conceptos Gastos</DropdownItem>
               <DropdownItem href="/ReporteProgramacion">Reporte Programacion</DropdownItem>
                  <DropdownItem href="/Proveedores">Proveedores</DropdownItem>
                </DropdownMenu>
                </Dropdown>
                </NavLink>
                </NavItem>
                  ) : undefined}

               {user.menu_admin ? (
                <NavItem>
                <NavLink className="titulosNav" >
                  <Dropdown isOpen={dropdownOpenCXP} toggle={toggleCXP}>
                  <DropdownToggle color="link">
                  <i class="fas fa-money-check-alt"></i>
                  <br/> CXP
                </DropdownToggle>
                <DropdownMenu>
                <h5 style={{color:"grey", paddingLeft:"7px"}}>CXP</h5>
                  <DropdownItem href="/ListadoComprasCXP">Compras Nacionales</DropdownItem>
                  <DropdownItem href="/ListadoComprasCXPInter">Compras Internacionales</DropdownItem>
                  <DropdownItem href="/ListadoAbonosProveedores">Pagos</DropdownItem>
                  <DropdownItem href="/EstadoCuentaCXP">Estado de Cuenta</DropdownItem>
                  <DropdownItem href="/ListadoDevolucionesProveedoresAdmin">Devoluciones</DropdownItem>
                </DropdownMenu>
                </Dropdown>
                </NavLink>
                </NavItem>
                  ) : undefined}
           

                {user.menu_ventas ? (
                <NavItem>
                <NavLink className="titulosNav" >
                  <Dropdown isOpen={dropdownOpenVentas} toggle={toggleVentas}>
                  <DropdownToggle color="link">
                  <i class="fas fa-dollar-sign"></i>
                  <br/> VENTAS
                </DropdownToggle>
                <DropdownMenu>
                <h5 style={{color:"grey", paddingLeft:"7px"}}>VENTAS</h5>
                  <DropdownItem href="/Zonas">Zonas</DropdownItem>
                  <DropdownItem href="/Clientes">Clientes</DropdownItem>
                  <DropdownItem href="/ListadoPedidos">Pedidos</DropdownItem>
                  <DropdownItem href="/ListadoApartados">Apartados</DropdownItem>
                  <DropdownItem href="/ListadoSurtidos">Surtidos</DropdownItem>
                  <DropdownItem href="/ListadoDevoluciones">Devoluciones</DropdownItem>
                </DropdownMenu>
                </Dropdown>
                </NavLink>
                </NavItem>
                  ) : undefined}


                  {user.menu_inventarios ? (
             <NavItem>
             <NavLink className="titulosNav" >
               <Dropdown isOpen={dropdownOpenInventarios} toggle={toggleInventarios}>
               <DropdownToggle color="link">
               <i class="fas fa-warehouse"></i>
               <br/> INVENTARIOS
             </DropdownToggle>
             <DropdownMenu>
             <h5 style={{color:"grey", paddingLeft:"7px"}}>INVENTARIOS</h5>
               <DropdownItem href="/ListadoInventariosGlobal">Inventarios</DropdownItem>
               <DropdownItem href="/ListadoInventarios">Inventarios Por Lote</DropdownItem>
               <DropdownItem href="/ListadoDisponible">Disponible</DropdownItem>
               <DropdownItem href="/ListadoDisponiblePendRecibir">Disponible Pend Recibir</DropdownItem>
               <DropdownItem href="/ListadoDisponibleVendedor">Disponible Vendedor</DropdownItem>
               <DropdownItem href="/ListadoSeguimiento">Seguimiento</DropdownItem>
               <DropdownItem href="/ListadoPendRecibir">Pendiente Recibir</DropdownItem>
               <DropdownItem href="/ListadoPendRecibirProveedor">Pend. Recibir Proveedor</DropdownItem>
             </DropdownMenu>
             </Dropdown>
             </NavLink>
             </NavItem>
               ) : undefined}


                  {user.menu_nominas ? (
             <NavItem>
             <NavLink className="titulosNav" >
               <Dropdown isOpen={dropdownOpenEmpleados} toggle={toggleEmpleados}>
               <DropdownToggle color="link">
               <i class="fas fa-address-card"></i>
               <br/> EMPLEADOS
             </DropdownToggle>
             <DropdownMenu>
             <h5 style={{color:"grey", paddingLeft:"7px"}}>EMPLEADOS</h5>
               <DropdownItem href="/Empleados">Empleados</DropdownItem>
               <DropdownItem href="/Puestos">Puestos</DropdownItem>
             </DropdownMenu>
             </Dropdown>
             </NavLink>
             </NavItem>
               ) : undefined}
               
                {user.menu_admin ? (
             <NavItem>
             <NavLink className="titulosNav" >
               <Dropdown isOpen={dropdownOpenAdmin} toggle={toggleAdmin}>
               <DropdownToggle color="link">
               <i class="fas fa-money-bill-wave"></i>
               <br/>ADMIN
             </DropdownToggle>
             <DropdownMenu>
             <h5 style={{color:"grey", paddingLeft:"7px"}}>ADMIN</h5>
               <DropdownItem href="/Bancos">Bancos</DropdownItem>
               <DropdownItem href="/ListadoVentas">Ventas</DropdownItem>
               <DropdownItem href="/EstadoCuenta">Estado de Cuenta</DropdownItem>
               <DropdownItem href="/AntiguedadSaldosPorCobrar">Antiguedad Saldos</DropdownItem>
               <DropdownItem href="/AntiguedadSaldosVencido">Saldos Vencidos</DropdownItem>
               <DropdownItem href="/ListadoAbonos">Abonos</DropdownItem>
               <DropdownItem href="/ListadoAnticipos">Anticipos</DropdownItem>
               <DropdownItem href="/ListadoNotasCargo">Notas de Cargo</DropdownItem>
               <DropdownItem href="/ListadoDevolucionesAdmin">Devoluciones</DropdownItem>
             </DropdownMenu>
             </Dropdown>
             </NavLink>
             </NavItem>
               ) : undefined}

{user.menu_fiscal ? (
             <NavItem>
             <NavLink className="titulosNav" >
               <Dropdown isOpen={dropdownOpenFiscal} toggle={toggleFiscal}>
               <DropdownToggle color="link">
               <i class="fas fa-money-bill-wave"></i>
               <br/>FISCAL
             </DropdownToggle>
             <DropdownMenu>
             <h5 style={{color:"grey", paddingLeft:"7px"}}>FISCAL</h5>
               <DropdownItem href="/ListadoEmpresas">Empresas</DropdownItem>
               <DropdownItem href="/ListadoFacturas">Facturas</DropdownItem>
               <DropdownItem href="/ListadoComplementosPago">Comp Pago</DropdownItem>
               <DropdownItem href="/ListadoPagosFacturas">Pagos a Facturas</DropdownItem>
               <DropdownItem href="/ListadoDevolucionesFiscal">Devoluciones</DropdownItem>
               <DropdownItem href="/ListadoNotasCredito">Notas de Credito</DropdownItem>
               <DropdownItem href="/EstadoCuentaK">Estado de Cuenta K</DropdownItem>
               <DropdownItem href="/RegistrarEmpresas">Registrar FIEL</DropdownItem>
             </DropdownMenu>
             </Dropdown>
             </NavLink>
             </NavItem>
               ) : undefined}
               
                    {/* <NavItem>
                      <NavLink className="titulosNav" href="/ListadoTareas">
                      <DropdownToggle color="link">
                      <i class="fas fa-tasks"></i>
                      <br/> TAREAS
                      </DropdownToggle>
                      </NavLink>
                    </NavItem> */}

                {user.menu_reportes ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuReportes">
                      <DropdownToggle color="link">
                      <i class="fas fa-chart-line"></i>
                      <br/> REPORTES
                      </DropdownToggle>
                      </NavLink>
                    </NavItem>
                  ) : undefined}

                  {user.menu_usuarios ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuUsuarios">
                      <DropdownToggle color="link">
                      <i class="fas fa-user"></i>
                      <br/> USUARIOS
                      </DropdownToggle>
                      </NavLink>
                    </NavItem>
                  ) : undefined}

                  {user.menu_logs ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuLogs">
                      <DropdownToggle color="link">
                      <i class="fas fa-list"></i>
                      <br/>LOGS
                      </DropdownToggle>
                      </NavLink>
                    </NavItem>
                  ) : undefined}

                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav">
                    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggleLogout}>
                      <DropdownToggle color="link">
                        <i class="fas fa-user-cog fa-2x"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="/logout">Logout</DropdownItem>
                        <DropdownItem onClick={togglePassword}>Password</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavLink>
                </NavbarText>
                <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Password />
                  </ModalBody>
                </Modal>
              </Collapse>
            </Navbar> 
            </>
         
        </header>
      ) : undefined}
    </>
  );
};

export default Header;
