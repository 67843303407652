import React from 'react'
import * as XLSX from 'xlsx'
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

class GetDataFromExcelJusTInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoja: "",
      hojas:[],
      file: false
    };

    this.handleInputChange = this.handleInputChange.bind(this)
  }
  handleInputChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const this2 = this
    this.setState({
      [name]: value
    })
    let hojas = []
    if (name === 'file') {
      let reader = new FileReader()
      reader.readAsArrayBuffer(target.files[0])
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {type: 'array'});

        workbook.SheetNames.forEach(function(sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          hojas.push({
            data: XL_row_object,
            sheetName
          })
        })
        console.log(hojas)

        let A1 = hojas[0].data.slice(0,1000)

        // Alta Inicial Articulos
        A1.map((a)=>{
 
                axios
                  .post(
                    `${process.env.REACT_APP_URL_ARTICULOS}`,
                    {
                      codigo: a.codigo,
                      codigoSAT: a.codigoSAT.toString(),
                      codigoUnidad: a.codigoUnidad,
                      nombre: a.nombre,
                      composicion: a.composicion,
                      descripcion: a.nombre,
                      unidad: a.unidad,
                      ancho: a.ancho,
                      peso: 0,
                      rendimiento: 0,
                      tiposTelas: a.tipoTela,
                      colecciones: "65847a1dba80b8a1d0b19908",
                      costo: 0,
                      venta: 0,
                      observaciones: "NA"
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                      },
                    }
                  )
      
                  .then(() => {
                    // axios.post(
                    //   process.env.REACT_APP_URL_LOGS,
                    //   {
                    //     tipo: "Inv Articulos",
                    //     detalle: `Inv Articulos`,
                    //     user: "613a9434225bce3df050c220",
                    //   },
                    //   {
                    //     headers: {
                    //       Authorization: `Bearer: ${localStorage.getItem(
                    //         "app_token"
                    //       )}`,
                    //     },
                    //   }
                    // );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                    });
                    console.log(error);
                  });


      })

        this2.setState({
          selectedFileDocument: target.files[0],
          hojas
        })
      }
    }

    
  } 


  
  render() {

    const {
      handleInputChange
    } = this
    return (
      <>
        <input 
            required 
            type="file" 
            name="file" 
            id="file" 
            onChange={handleInputChange} 
            placeholder="Archivo de excel" 
        />
      </>
    );
  }
}

export default GetDataFromExcelJusTInput




// A1.map((a)=>{

//   axios
//   .post(
//     process.env.REACT_APP_URL_CLIENTES,
//     {
//       nombre_comercial:a.nombre_comercial,
//       razon_social:a.razon_social,
//       RFC:a.RFC,
//       calle:a.calle,
//       numero_ext:a.numero_ext,
//       numero_int:a.numero_int,
//       delegacion:a.delegacion,
//       estado:a.estado,
//       cp:a.cp,
//       colonia:a.colonia,
//       ciudad:a.ciudad,
//       telefono:a.telefono,
//       email:a.email,
//       grupos:a.grupos,
//       departamentosClientes:a.departamentosClientes,
//       vendedor:a.vendedor,
//       observaciones:a.observaciones,
//       forma_pago:a.forma_pago,
//       codigo:a.codigo,
//       areas:a.areas,
//       plazo:a.plazo,
//       regFiscal:a.regFiscal,
//       cargos:0,
//       abonos:0,
//       saldo:0,
//       ultimo_pago: "2000-01-01",
//       pais:"Mexico"
//     },
//     {
//       headers: {
//         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//       },
//     }
//   )
//   .then((response) => {
//     const idPost = response.data._id
//     axios
//       .post(
//         process.env.REACT_APP_URL_SUCURSALES,
//         {
//           calle:a.calle,
//           numero_ext:a.numero_ext,
//           numero_int:a.numero_int,
//           delegacion:a.delegacion,
//           estado:a.estado,
//           cp:a.cp,
//           colonia:a.colonia,
//           ciudad:a.ciudad,
//           RFC:a.RFC,
//           razon_social:a.razon_social,
//           pais:"Mexico",
//           paqueteria:a.paqueteria,                  
//           clientes: response.data._id,
//           alias: "Principal",              
//         },
//         {
//           headers: {
//             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//           },
//         }
//       )
//       .then(() => {
//         axios.post(
//           process.env.REACT_APP_URL_LOGS,
//           {
//             tipo: "Crear Cliente Tiendas",
//             detalle: `Crear Cliente Tiendas`,
//             user: "613a9434225bce3df050c220",
//           },
//           {
//             headers: {
//               Authorization: `Bearer: ${localStorage.getItem(
//                 "app_token"
//               )}`,
//             },
//           }
//         );
//         Swal.fire("Good job!", "Creado con exito", `success`);
//       })
//       .catch((error) => {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Something went wrong!",
//           footer: `${error.response.data.message}`,
//         });
//         console.log(error);
//       });
//   })
//   .catch((error) => {
//     Swal.fire({
//       icon: "error",
//       title: "Oops...",
//       text: "Something went wrong!",
//       footer: `${error.response.data.message}`,
//     });
//     console.log(error);
//   });

// axios
// .post(
//   `${process.env.REACT_APP_URL_SUCURSALES}`,
//   {
//     calle: a.calle,
//     numero_ext: a.numero_ext,
//     numero_int: a.numero_int,
//     delegacion: a.delegacion,
//     estado: a.estado,
//     pais: a.pais,
//     cp: a.cp,
//     colonia: a.colonia,
//     ciudad: a.ciudad,
//     clientes: a.id,
//     alias: a.alias,
//     RFC: a.RFC,
//     razon_social: a.razon_social,
//     paqueteria: a.paqueteria
//   },
//   {
//     headers: {
//       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//     },
//   }
// )




// Alta Inicial Empleados
  // axios
          // .post(
          //   process.env.REACT_APP_URL_COLABORADORES,
          //   {
          //     nombre:a.nombre,
          //     apellido:a.apellido,
          //     identificacion:a.identificacion,
          //     email:a.email,
          //     direccion:a.direccion,
          //     fecha_ingreso:a.fecha_ingreso,
          //     fecha_nacimiento:a.fecha_nacimiento,
          //     frecuencia:a.frecuencia,
          //     telefono:a.telefono,
          //     contacto_emergencia:a.contacto_emergencia,
          //     telefono_emergencia:a.telefono_emergencia,
          //     sueldo_actual:a.sueldo_actual,
          //     observaciones:a.observaciones,
          //     puestos:a.puestos,
          //     banco:a.banco,
          //     clabe:a.clabe,
          //     cobrador:a.cobrador,
          //     ultimo_aumento:a.ultimo_aumento,
          //     sueldo_anterior:a.sueldo_anterior,
          //     planteles:a.planteles,
          //     areas:a.areas,
          //     acceso_sistema:a.acceso_sistema,
          //   },
          //   {
          //     headers: {
          //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          //     },
          //   }
          // )   
          // .then((response) => {
          //   console.log(a.email)
          //   axios
          //     .get(`${process.env.REACT_APP_URL_COLABORADORES}/email/${a.email}`, {
          //       headers: {
          //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          //       },
          //     })
          // .then((response)=>{
            // const idPost = response.data[0]._id;
            // axios
            // .post(
            //   process.env.REACT_APP_URL_USERS,
            //   {
            //     nombre:a.nombre,
            //     apellido:a.apellido,
            //     email:a.email,
            //     password:a.password,
            //     planteles: a.planteles,
            //     areas: a.areas,
            //     is_active: a.is_active,
            //     colaboradores: a.id,
            //   },
            //   {
            //     headers: {
            //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            //     },
            //   }
            // )
  
            // .then(() => {
            //   axios.post(
            //     process.env.REACT_APP_URL_LOGS,
            //     {
            //       tipo: "Alta inicial empleados",
            //       detalle: `Sistemify es lo de hoy!`,
            //       user: "613a9434225bce3df050c220"                ,
            //     },
            //     {
            //       headers: {
            //         Authorization: `Bearer: ${localStorage.getItem(
            //           "app_token"
            //         )}`,
            //       },
            //     }
            //   );
            //   Swal.fire("Good job!", "Creado con exito", `success`);
            //   // setTimeout(() => {
            //   //   window.location.reload();
            //   // }, 1000);
            // })
            // .catch((error) => {
            //   Swal.fire({
            //     icon: "error",
            //     title: "Oops...",
            //     text: "Something went wrong!",
            //     footer: `${error.response}`,
            //   });
            //   console.log(error);
            // })
            
  
          // })
          // .catch((error) => {
          //   Swal.fire({
          //     icon: "error",
          //     title: "Oops...",
          //     text: "Something went wrong!",
          //   });
          //   console.log(error);
          // })
        // })
        // .catch((error) => {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Something went wrong!",
        //     footer: `${error.response.data}`,
        //   });
        //   console.log(error);
        // });