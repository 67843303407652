import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import EmpresasCreate from "./EmpresasCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import { v4 as uuidv4 } from "uuid";

function ListadoEmpresas() {
  const { user } = useContext(AuthContext);
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CUENTAS_EMPRESAS = process.env.REACT_APP_URL_CUENTAS_EMPRESAS;

  const [empresas, setEmpresas] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [rfc, setRfc] = useState("");
  const [cp, setCP] = useState("");
  const [regimenFiscal, setRegimenFiscal] = useState("");
  const [direccionFiscal, setDireccionFiscal] = useState("");
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [idEdit, setIdEdit] = useState()

  const [cuentas, setCuentas] = useState([]);
    const [idEmpresaCuenta, setIdEmpresaCuenta] = useState("");
    const [modalCuenta, setModalCuenta] = useState(false);
    const toggleCuenta = () => setModalCuenta(!modalCuenta);

    const [inputFieldsCuentas, setInputFieldsCuentas] = useState([
      {
        id: uuidv4(),
        banco: "",
        cuenta: "",
        rfc:"",
      },
    ]);


  useEffect(() => {
    axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresas = response.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editEmpresa(event) {
    event.preventDefault();
    const URL_EMPRESAS_EDIT = `${process.env.REACT_APP_URL_EMPRESAS}/${idEdit}`;
    axios
      .patch(
        URL_EMPRESAS_EDIT,
        {
          name,
          prefijo,
          razonSocial,
          rfc,
          cp,
          regimenFiscal,
          direccionFiscal,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Empresa',
            detalle: name,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, name, prefijo, razonSocial, rfc, cp, regimenFiscal, direccionFiscal){ 
        setName(name);
        setPrefijo(prefijo)
        setRazonSocial(razonSocial)
        setRfc(rfc)
        setCP(cp)
        setRegimenFiscal(regimenFiscal)
        setDireccionFiscal(direccionFiscal)
        setIdEdit(idEdit);
        toggle();
  }

  function cue(id, razonSocial) {
    setIdEmpresaCuenta(id);
    setRazonSocial(razonSocial);
    axios
      .get(`${URL_CUENTAS_EMPRESAS}/empresa/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCuentas = res.data;
        setCuentas(allCuentas);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleCuenta();
  }
  
  const handleChangeInputCuentasExistentes = (id, event) => {
    const newCuentas = cuentas.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setCuentas(newCuentas);
  };
  
  const handleChangeInputCuentas = (id, event) => {
    const newInputFieldsCuentas = inputFieldsCuentas.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsCuentas(newInputFieldsCuentas);
  };
  
  function saveCuenta() {
    let totalCuen = cuentas.length + inputFieldsCuentas.length;
    cuentas.map((a) => {
      if (a.cuenta != "") {
        axios
          .patch(
            `${URL_CUENTAS_EMPRESAS}/${a._id}`,
            {
              cuenta: a.cuenta,
              banco: a.banco,
              rfc: a.rfc,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalCuen = totalCuen - 1;
            if (totalCuen == 0) {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Cuentas",
                  detalle: `Empresa ${idEmpresaCuenta} / Cuenta ${a.cuenta}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } else {
        totalCuen = totalCuen - 1
        if (totalCuen == 0) {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Cuenta",
              detalle: `Empresa ${idEmpresaCuenta} / Cuenta ${a.cuenta}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem(
                  "app_token"
                )}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    });
  
    // agregar inputFields
    inputFieldsCuentas.map((a) => {
      if (a.cuenta != "") {
        axios
          .post(
            `${URL_CUENTAS_EMPRESAS}`,
            {
              empresas: idEmpresaCuenta,
              cuenta: a.cuenta,
              banco: a.banco,
              rfc: a.rfc,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalCuen = totalCuen - 1;
            if (totalCuen == 0) {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Cuenta",
                  detalle: `Empresa ${idEmpresaCuenta} / Cuenta ${a.cuenta}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } else {
        totalCuen = totalCuen - 1
        if (totalCuen == 0) {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Cuenta",
              detalle: `Empresa ${idEmpresaCuenta} / Cuenta ${a.cuenta}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem(
                  "app_token"
                )}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    });
  }
  
  const handleAddFieldsCuentas = () => {
    setInputFieldsCuentas([
      ...inputFieldsCuentas,
      {
        id: uuidv4(),
        banco: "",
        cuenta: "",
        rfc:"",
      },
    ]);
  };
  
  const handleRemoveFieldsCuentas = (id) => {
    const values = [...inputFieldsCuentas];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsCuentas(values);
  };
  
  function altaCuenta(id) {
    axios
      .patch(
        `${URL_CUENTAS_EMPRESAS}/${id}`,
        {
          is_active: "Si"
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
          },
        }
      )
      .then((data) => {
            Swal.fire("Good job!", "Creado con exito", "success");
  
            const newCuentas = cuentas.map((i) => {
              if (id === i._id) {
                i.is_active = "Si";
              }
              return i;
            });
            setCuentas(newCuentas);
         
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          // footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  
  function bajaCuenta(id) {
  axios
  .patch(
  `${URL_CUENTAS_EMPRESAS}/${id}`,
  {
  is_active: "No"
  },
  {
  headers: {
    Authorization: `Bearer: ${localStorage.getItem(
      "app_token"
    )}`,
  },
  }
  )
  .then((data) => {
    Swal.fire("Good job!", "Creado con exito", "success");
    const newCuentas = cuentas.map((i) => {
      if (id === i._id) {
        i.is_active = "No";
      }
      return i;
    });
    setCuentas(newCuentas);
  })
  .catch((error) => {
  Swal.fire({
  icon: "error",
  title: "Oops...",
  text: "Something went wrong!",
  // footer: `${error.response.data}`,
  });
  console.log(error);
  });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        { user.menu_admin ? (
          <div className="row">
            {
              <div className="col-md-9">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Empresas</h3>
                    <Table
                      // size="sm"
                      // striped
                      // borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Prefijo</th>
                          <th className="tituloTabla">Razon Social</th>
                          <th className="tituloTabla">RFC</th>
                          <th className="tituloTabla">Regimen Fiscal</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {empresas
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.name}</td>
                                  <td>{a.prefijo}</td>
                                  <td>{a.razonSocial}</td>
                                  <td>{a.rfc}</td>
                                  <td>{a.regimenFiscal}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => jalaInfo(a._id, a.name, a.prefijo, a.razonSocial, a.rfc, a.cp, a.regimenFiscal, a.direccionFiscal)}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>
                                    <Button
                                  color="info"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    cue(a._id, a.razonSocial)
                                  }
                                >
                                  <i class="fas fa-dollar-sign"></i>
                                </Button>
                                    <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_EMPRESAS
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-3">
              <div className="card">
                <div className="card-body">
                  <EmpresasCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Empresa</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Label className="mr-sm-2">
              Prefijo
            </Label>
            <Input
              type="text"
              name="prefijo"
              placeholder="Prefijo"
              value={prefijo}
              required
              onChange={(e) => {
                setPrefijo(e.target.value);
              }}
            />
             <Label className="mr-sm-2">
              Razon Social
            </Label>
            <Input
              type="text" 
              placeholder="Razon Social"
              value={razonSocial}
              required
              onChange={(e) => {
                setRazonSocial(e.target.value);
              }}
            />
            <Label className="mr-sm-2">
              RFC
            </Label>
            <Input
              type="text" 
              placeholder="RFC"
              value={rfc}
              required
              onChange={(e) => {
                setRfc(e.target.value);
              }}
            />
            <Label className="mr-sm-2">
              Regimen Fiscal
            </Label>
            <Input
              type="select" 
              placeholder="Regimen Fiscal"
              value={regimenFiscal}
              required
              onChange={(e) => {
                setRegimenFiscal(e.target.value);
              }}
            >
               <option value="">Reg Fiscal</option>
                  <option value="0">0</option>
                  <option value="601">601 General de Ley Personas Morales</option>
                  <option value="603">603 Personas Morales con Fines no Lucrativos</option>
                  <option value="605">605 Sueldos y Salarios e Ingresos Asimilados al Salario</option>
                  <option value="608">608 Demas Ingresos</option>
                  <option value="612">612 Personas Físicas con Actividades Empresariales y Profesionales</option>
                  <option value="616">616 Sin Obligacion Fiscal</option>
                  <option value="621">621 Incorporacion Fiscal</option>
                  <option value="624">624 Régimen de los Coordinados</option>
                  <option value="625">625 Reg. de las act. emp. cpn ingresos a traves de plat. tec.</option>
                  <option value="626">626 Regimen simplificado de Confianza</option>
              </Input>
              <Label className="mr-sm-2">
              Direccion Fiscal
            </Label>
            <Input
              type="text" 
              placeholder="Direccion Fiscal"
              value={direccionFiscal}
              required
              onChange={(e) => {
                setDireccionFiscal(e.target.value);
              }}
            />
            <Label className="mr-sm-2">
              CP
            </Label>
             <Input
              type="text" 
              placeholder="CP"
              value={cp}
              required
              onChange={(e) => {
                setCP(e.target.value);
              }}
            />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editEmpresa}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xl" isOpen={modalCuenta} toggle={toggleCuenta}>
        <ModalHeader toggle={toggleCuenta}>
          <h4>Cuentas {razonSocial}</h4>
        </ModalHeader>
        <ModalBody>
        <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>RFC</th>
                      <th>Cuentas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cuentas.map((a) => (
                      <tr>
                         <td>
                          <Input
                            type="text"
                            name="banco"
                            value={a.banco}
                            key={a._id}
                            onChange={(e) => {
                              handleChangeInputCuentasExistentes(a._id, e);
                            }}
                          />
                        </td>
                         <td>
                          <Input
                            type="text"
                            name="rfc"
                            value={a.rfc}
                            key={a._id}
                            onChange={(e) => {
                              handleChangeInputCuentasExistentes(a._id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            name="cuenta"
                            type="text"
                            value={a.cuenta}
                            required
                            // maxLength="4" // Limit to 4 digits
                            // pattern="\d{1,4}" 
                            onChange={(event) => {
                              handleChangeInputCuentasExistentes(a._id, event);
                            }}
                          />
                        </td>
                        <td>
                          {a.is_active == "Si"?(
                            <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={() => bajaCuenta(a._id)}
                            tabindex="-1"
                          >
                            <i class="far fa-times-circle"></i>
                          </Button>
                          ):(
                            <Button
                            size="sm"
                            className="btn"
                            color="primary"
                            onClick={() => altaCuenta(a._id)}
                            tabindex="-1"
                          >
                            <i class="far fa-check-circle"></i>
                          </Button>
                          )}
                         
                        </td>
                      </tr>
                      // </div>
                    ))}

              {inputFieldsCuentas.map((a) => (
                      <tr>
                         <td>
                          <Input
                            type="text"
                            name="banco"
                            value={a.banco}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputCuentas(a.id, e);
                            }}
                          />
                        </td>
                         <td>
                          <Input
                            type="text"
                            name="rfc"
                            value={a.rfc}
                            key={a._id}
                            onChange={(e) => {
                              handleChangeInputCuentas(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            name="cuenta"
                            type="text"
                            value={a.cuenta}
                            required
                            // maxLength="4" // Limit to 4 digits
                            // pattern="\d{1,4}" 
                            onChange={(event) => {
                              handleChangeInputCuentas(a.id, event);
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            disabled={a.length === 1}
                            onClick={() => handleRemoveFieldsCuentas(a.id)}
                            tabindex="-1"
                          >
                            <i class="fas fa-minus"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={handleAddFieldsCuentas}
                            tabindex="-1"
                          >
                            <i class="fas fa-plus"></i>
                          </Button>
                        </td>
                      </tr>
                      // </div>
                    ))}

                  </tbody>
                  <br />
                </Table>
                <br />
                <Row>
                <Button className="btn btn-success" onClick={saveCuenta}>
                Registrar
              </Button>
                </Row>

        </ModalBody>
      </Modal>
    </>
  );
}

export default ListadoEmpresas;
