import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { func } from "prop-types";
import qrcode from "qrcode-js";

function ListadoInventariosGlobal() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;

  const [inventarios, setInventarios] = useState([]);
  const [inventariosRollos, setInventariosRollos] = useState([]);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colecciones, setColecciones] = useState([]);
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedComposicion, setSelectedComposicion] = useState("");
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [articulosComposicion, setArticulosComposicion] = useState([]);
  const [articuloDesgloce, setArticuloDesgloce] = useState("");
  const [nombreDesgloce, setNombreDesgloce] = useState("");
  const [codigoDesgloce, setCodigoDesgloce] = useState("");
  const [colorDesgloce, setColorDesgloce] = useState("");
  const [claveDesgloce, setClaveDesgloce] = useState("");
  const [pesoDesgloce, setPesoDesgloce] = useState("");
  const [composicionDesgloce, setComposicionDesgloce] = useState("");
  const [unidadDesgloce, setUnidadDesgloce] = useState("");
  const [anchoDesgloce, setAnchoDesgloce] = useState("");
  const [tiposTelasDesgloce, setTiposTelasDesgloce] = useState("");

  const [articulosCodigos, setArticulosCodigos] = useState([]);
  const [articulosNombre, setArticulosNombre] = useState([]);
  const [selectedCodigo, setSelectedCodigo] = useState("");
  const [selectedNombre, setSelectedNombre] = useState("");

  const [idRolloEdit, setIdRolloEdit] = useState("");
  const [articuloEdit, setArticuloEdit] = useState("");
  const [unidadEdit, setUnidadEdit] = useState("");
  const [cantidadEdit, setCantidadEdit] = useState("");
  const [cantidadAnteriorEdit, setCantidadAnteriorEdit] = useState("");
  const [claveEdit, setClaveEdit] = useState("");
  const [vendidoEdit, setVendidoEdit] = useState("No");
  const [apartadoEdit, setApartadoEdit] = useState("No");
  const [idCompraEdit, setIdCompraEdit] = useState("");
  const [numeroCompraEdit, setNumeroCompraEdit] = useState("");

  const [idRolloHistorial, setIdRolloHistorial] = useState("");
  const [articuloHistorial, setArticuloHistorial] = useState("");
  const [unidadHistorial, setUnidadHistorial] = useState("");
  const [cantidadHistorial, setCantidadHistorial] = useState("");
  const [cantidadAnteriorHistorial, setCantidadAnteriorHistorial] =
    useState("");
  const [claveHistorial, setClaveHistorial] = useState("");
  const [vendidoHistorial, setVendidoHistorial] = useState("No");
  const [idCompraHistorial, setIdCompraHistorial] = useState("");
  const [numeroCompraHistorial, setNumeroCompraHistorial] = useState("");
  const [surtidoHistorial, setSurtidoHistorial] = useState("");

  const [unidad, setUnidad] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalMailRollo, setModalMailRollo] = useState(false);
  const toggleMailRollo = () => setModalMailRollo(!modalMailRollo);

  const [modalDesgloce, setModalDesgloce] = useState(false);
  const toggleDesgloce = () => setModalDesgloce(!modalDesgloce);

  const [modalEditRollo, setModalEditRollo] = useState(false);
  const toggleEditRollo = () => setModalEditRollo(!modalEditRollo);

  const [modalCortarRollo, setModalCortarRollo] = useState(false);
  const toggleCortarRollo = () => setModalCortarRollo(!modalCortarRollo);
  const [cantidadCortar, setCantidadCortar] = useState("");
  const [rolloCortar, setRolloCortar] = useState("");

  const [totalCantidadMetros2, setTotalCantidadMetros2] = useState(0);
  const [totalCantidadMetros, setTotalCantidadMetros] = useState(0);
  const [totalCantidadKilos, setTotalCantidadKilos] = useState(0);

  const [modalHistorialRollo, setModalHistorialRollo] = useState(false);
  const toggleHistorialRollo = () =>
    setModalHistorialRollo(!modalHistorialRollo);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(`${URL_INVENTARIOS}AcumuladoGlobal`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventarios = response.data;
        // Array para pagination
        let arrayTabla = allInventarios
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            let metros2;
            if (a.unidad === "Metros") {
              metros2 = a.cantidad;
            } else if (a.unidad === "Kilos") {
              metros2 = a.cantidad / a.rendimiento;
            } else {
              metros2 = 0; // Default value or handle other cases
            }
            return {
              _id: a._id,
              cantidad: a.cantidad,
              clave: a.clave,
              codigo: a.codigo,
              nombre: a.nombre,
              idArticulo: a.idArticulo,
              composicion: a.composicion,
              descripcion: a.descripcion,
              unidad: a.unidad,
              ancho: a.ancho,
              peso: a.peso,
              rendimiento: a.rendimiento,
              idTiposTelas: a.idTiposTelas,
              tiposTelas: a.tiposTelas,
              costo: a.costo,
              venta: a.venta,
              costoTotal: a.costoTotal,
              ventaTotal: a.ventaTotal,
              observaciones: a.observaciones,
              colores: a.colores,
              idColores: a.idColores,
              idCompras: a.idCompras,
              rollos: parseFloat(a.rollos),
              idProveedor: a.idProveedor,
              proveedor: a.proveedor,
              compra: a.compra,
              coleccion: a.coleccion,
              idColeccion: a.idColeccion,
              metros2: metros2
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
  
        setInventarios(dataFinal);
        setComments(dataFinal);

        var items = dataFinal.map((a) => {
          return a.composicion;
        });

        const unicos = [];
        for (var i = 0; i < items.length; i++) {
          const elemento = items[i];

          if (!unicos.includes(items[i])) {
            unicos.push(elemento);
          }
        }
        setArticulosComposicion(unicos);

        var itemsCodigos = dataFinal.map((a) => {
          return a.codigo;
        });

        let agrupadoCodigos = dataFinal.reduce(function (groups, item) {
          const val = item["codigo"];
          groups[val] = groups[val] || {
            codigo: item.codigo,
            cantidad: 0,
          };
          groups[val].codigo = item.codigo;
          groups[val].idArticulo = item.idArticulo;
          groups[val].cantidad += item.cantidad;
          return groups;
        }, []);
        let dataFinalCodigos = Object.values(agrupadoCodigos);
        setArticulosCodigos(dataFinalCodigos);

        let agrupadoNombres = dataFinal.reduce(function (groups, item) {
          const val = item["nombre"];
          groups[val] = groups[val] || {
            nombre: item.nombre,
            idArticulo: "",
          };
          groups[val].nombre = item.nombre;
          groups[val].idArticulo = item.idArticulo;
          return groups;
        }, []);
        let dataFinalNombres = Object.values(agrupadoNombres);
        setArticulosNombre(dataFinalNombres);
      })

      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TIPOS_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTiposTelas = response.data;
        setTiposTelas(allTiposTelas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_COLECCIONES, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allColecciones = response.data;
          setColecciones(allColecciones);
        })
        .catch((err) => {
          console.log(err);
        });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaInventarios = comments.sort((a, b) =>
    a.codigo > b.codigo ? 1 : -1
  );

  function PDFTabla() {
    let total_general = 0;
    let total_generalCosto = 0;
    let total_generalVenta = 0;

    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idColores"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        idColores: item.idColores,
        cantidad: 0,
        metros2: 0,
        rollos: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].metros2 += item.metros2;
      groups[val]._id = item._id;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].nombre = item.nombre;
      groups[val].idArticulo = item.idArticulo;
      groups[val].composicion = item.composicion;
      groups[val].descripcion = item.descripcion;
      groups[val].unidad = item.unidad;
      groups[val].ancho = item.ancho;
      groups[val].peso = item.peso;
      groups[val].rendimiento = item.rendimiento;
      groups[val].idTiposTelas = item.idTiposTelas;
      groups[val].tiposTelas = item.tiposTelas;
      groups[val].costo = item.costo;
      groups[val].venta = item.venta;
      groups[val].costoTotal = item.costoTotal;
      groups[val].ventaTotal = item.ventaTotal;
      groups[val].observaciones = item.observaciones;
      groups[val].colores = item.colores;
      groups[val].idColores = item.idColores;
      groups[val].rollos += item.rollos;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      groups[val].idColeccion = item.idColeccion;
      groups[val].coleccion = item.coleccion;
      return groups;
    }, []);

    let computedComments = Object.values(agrupado);

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.ancho.toString().includes(search) ||
          comment.peso.toString().includes(search) ||
          comment.venta.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.ventaTotal.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) ||
          comment.rollos.toString().includes(search) ||
          comment.metros2.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      );
    }

    if (selectedTiposTelasFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposTelas.includes(selectedTiposTelasFiltro)
      );
    }
    if (selectedColor) {
      computedComments = computedComments.filter((e) =>
        e.idColores.includes(selectedColor)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedNombre)
      );
    }
    if (unidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(unidad)
      );
    }
    if (selectedComposicion) {
      computedComments = computedComments.filter((e) =>
        e.composicion.includes(selectedComposicion)
      );
    }
    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "metros2" &&
      sorting.field != "ancho" &&
      sorting.field != "peso" &&
      sorting.field != "venta" &&
      sorting.field != "costo" &&
      sorting.field != "ventaTotal" &&
      sorting.field != "costoTotal" &&
      sorting.field != "rollos" &&
      sorting.field != "codigo" &&
      sorting.field != "costoMt"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "metros2" ||
        sorting.field == "ancho" ||
        sorting.field == "peso" ||
        sorting.field == "venta" ||
        sorting.field != "costo" ||
        sorting.field != "ventaTotal" ||
        sorting.field != "costoTotal" ||
        sorting.field != "rollos" ||
        sorting.field != "codigo" ||
        sorting.field != "costoMt")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "metros2" ||
        sorting.field == "ancho" ||
        sorting.field == "peso" ||
        sorting.field == "venta" ||
        sorting.field != "costo" ||
        sorting.field != "ventaTotal" ||
        sorting.field != "costoTotal" ||
        sorting.field != "rollos" ||
        sorting.field != "codigo" ||
        sorting.field != "costoMt")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    const data = computedComments
      .map((a) => {

          total_generalCosto =
            parseFloat(total_generalCosto) + parseFloat(a.costoTotal);
          total_generalVenta =
            parseFloat(total_generalVenta) + parseFloat(a.ventaTotal);
          return [
            a.nombre,
            a.colores,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.metros2),
            a.unidad,
            a.ancho,
            a.composicion,
            a.coleccion,
            a.peso,
            a.tiposTelas,
          ];
        }
      );
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Inventarios`, 20, 40);
    doc.autoTable({
      // styles: {
      //     valign: 'center'
      // },
      head: [
        [
          "Nombre",
          "Color",
          "Cantidad",
          "Metros",
          "Unidad",
          "Ancho",
          "Composicion",
          "Colecion",
          "Peso",
          "Tipo de Tela",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      // styles: {overflow: 'linebreak'},
      // columnStyles: {
      // "Codigo": {minCellWidth: 50},
      // "Nombre": {minCellWidth: 50},
      // "Color": {minCellWidth: 50},
      // "Composicion": {minCellWidth: 50},
      // "Unidad": {minCellWidth: 50},
      // "Ancho": {minCellWidth: 50},
      // "Peso": {minCellWidth: 50},
      // "Tipo de Tela": {minCellWidth: 50},
      // "Venta": {minCellWidth: 50},
      //   Cantidad: {cellWidth: 300},
      //  },
      foot: [
        [
          "",
          "Metros",
          new Intl.NumberFormat("en-US").format(totalCantidadMetros),
          "Kilos",
          new Intl.NumberFormat("en-US").format(totalCantidadKilos),
          "Total Metros",
          new Intl.NumberFormat("en-US").format(totalCantidadMetros2),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Inventarios.pdf`);
  }

  function excel() {
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idColores"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        idColores: item.idColores,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val]._id = item._id;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].nombre = item.nombre;
      groups[val].idArticulo = item.idArticulo;
      groups[val].composicion = item.composicion;
      groups[val].idColeccion = item.idColeccion;
      groups[val].coleccion = item.coleccion;
      groups[val].descripcion = item.descripcion;
      groups[val].unidad = item.unidad;
      groups[val].ancho = item.ancho;
      groups[val].peso = item.peso;
      groups[val].rendimiento = item.rendimiento;
      groups[val].idTiposTelas = item.idTiposTelas;
      groups[val].tiposTelas = item.tiposTelas;
      groups[val].costo = item.costo;
      groups[val].venta = item.venta;
      groups[val].costoTotal = item.costoTotal;
      groups[val].ventaTotal = item.ventaTotal;
      groups[val].observaciones = item.observaciones;
      groups[val].colores = item.colores;
      groups[val].idColores = item.idColores;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      return groups;
    }, []);

    let computedComments = Object.values(agrupado);

    const dataExcel = computedComments
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.ancho.toString().includes(search) ||
          comment.peso.toString().includes(search) ||
          comment.venta.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.ventaTotal.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) ||
          comment.rollos.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      )
      .map((a) => {
        if (
          (selectedTiposTelasFiltro == 0 ||
            selectedTiposTelasFiltro == a.idTiposTelas) &&
          (selectedColor == 0 || selectedColor == a.idColores) &&
          (selectedCodigo == 0 || selectedCodigo == a.idArticulo) &&
          (selectedNombre == 0 || selectedNombre == a.idArticulo) &&
          (unidad == 0 || unidad == a.unidad) &&
          (selectedComposicion == 0 || selectedComposicion == a.composicion) &&
          (selectedColeccion == 0 ||
            selectedColeccion == a.idColeccion) &&
          (selectedProveedor == 0 || selectedProveedor == a.idProveedor)
        ) {
          return {
            Codigo: a.codigo,
            Nombre: a.nombre,
            Color: a.colores,
            Composicion: a.composicion,
            Cleccion: a.cleccion,
            Unidad: a.unidad,
            Ancho: a.ancho,
            Peso: a.peso,
            Tipo: a.tiposTelas,
            Venta: a.venta,
            Cantidad: a.cantidad,
            CostoTotal: a.costoTotal,
            VentaTotal: a.ventaTotal,
          };
        }
      });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoInventariosGlobal";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoInventariosGlobal",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "Color",
          "Lote",
          "Composicion",
          "Cleccion",
          "Unidad",
          "Ancho",
          "Peso",
          "Tipo",
          "Venta",
          "Cantidad",
          "CostoTotal",
          "VentaTotal",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "Color",
          "Lote",
          "Composicion",
          "Cleccion",
          "Unidad",
          "Ancho",
          "Peso",
          "Tipo",
          "Venta",
          "Cantidad",
          "CostoTotal",
          "VentaTotal",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_general = 0;
    let total_generalCosto = 0;
    let total_generalVenta = 0;
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idColores"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        idColores: item.idColores,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val]._id = item._id;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].nombre = item.nombre;
      groups[val].idArticulo = item.idArticulo;
      groups[val].composicion = item.composicion;
      groups[val].descripcion = item.descripcion;
      groups[val].unidad = item.unidad;
      groups[val].ancho = item.ancho;
      groups[val].peso = item.peso;
      groups[val].rendimiento = item.rendimiento;
      groups[val].idTiposTelas = item.idTiposTelas;
      groups[val].tiposTelas = item.tiposTelas;
      groups[val].costo = item.costo;
      groups[val].venta = item.venta;
      groups[val].costoTotal = item.costoTotal;
      groups[val].ventaTotal = item.ventaTotal;
      groups[val].observaciones = item.observaciones;
      groups[val].colores = item.colores;
      groups[val].idColores = item.idColores;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      return groups;
    }, []);

    let computedComments = Object.values(agrupado);

    const data = computedComments
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.ancho.toString().includes(search) ||
          comment.peso.toString().includes(search) ||
          comment.venta.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.ventaTotal.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) ||
          comment.rollos.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      )
      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
      .map((a) => {
        if (
          (selectedTiposTelasFiltro == 0 ||
            selectedTiposTelasFiltro == a.idTiposTelas) &&
          (selectedColor == 0 || selectedColor == a.idColores) &&
          (selectedCodigo == 0 || selectedCodigo == a.idArticulo) &&
          (selectedNombre == 0 || selectedNombre == a.idArticulo) &&
          (unidad == 0 || unidad == a.unidad) &&
          (selectedComposicion == 0 || selectedComposicion == a.composicion) &&
          (selectedProveedor == 0 || selectedProveedor == a.idProveedor)
        ) {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad);
          total_generalCosto =
            parseFloat(total_generalCosto) + parseFloat(a.costoTotal);
          total_generalVenta =
            parseFloat(total_generalVenta) + parseFloat(a.ventaTotal);
          return [
            a.codigo,
            a.nombre,
            a.colores,
            a.composicion,
            a.unidad,
            a.ancho,
            a.peso,
            a.tiposTelas,
            // "$" + new Intl.NumberFormat("en-US").format(a.costo),
            "$" + new Intl.NumberFormat("en-US").format(a.venta),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "$" + new Intl.NumberFormat("en-US").format(a.costoTotal),
            "$" + new Intl.NumberFormat("en-US").format(a.ventaTotal),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Inventarios`, 20, 40);
    doc.autoTable({
      // styles: {
      //     valign: 'center'
      // },
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      footStyles: {
        valign: "middle",
        halign: "right",
      },
      columnStyles: {
        0: { minCellWidth: 20 },
        1: { minCellWidth: 20 },
        2: { minCellWidth: 18 },
        3: { minCellWidth: 10, fontSize: 8 },
        4: {
          minCellWidth: 20,
          // valign: 'center'
        },
        5: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        6: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        // 7:{ minCellWidth: 20,
        //   // valign: 'center'
        //  },
        8: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        9: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        10: {
          minCellWidth: 22,
          halign: "right",
          // valign: 'center'
        },
        11: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        12: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
      },
      head: [
        [
          "Codigo",
          "Nombre",
          "Color",
          "Lote",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Tipo de Tela",
          // "Costo",
          "Venta",
          "Cantidad",
          "Costo Total",
          "Venta Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      // styles: {overflow: 'linebreak'},
      // columnStyles: {
      // "Codigo": {minCellWidth: 50},
      // "Nombre": {minCellWidth: 50},
      // "Color": {minCellWidth: 50},
      // "Composicion": {minCellWidth: 50},
      // "Unidad": {minCellWidth: 50},
      // "Ancho": {minCellWidth: 50},
      // "Peso": {minCellWidth: 50},
      // "Tipo de Tela": {minCellWidth: 50},
      // "Venta": {minCellWidth: 50},
      //   Cantidad: {cellWidth: 300},
      //  },
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_general),
          "$" + new Intl.NumberFormat("en-US").format(total_generalCosto),
          "$" + new Intl.NumberFormat("en-US").format(total_generalVenta),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Inventarios",
          email: mailTo,
          fileName: "ListadoInventariosGlobal.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Inventarios.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Color", field: "colores", sortable: true },
    { name: "Composicion", field: "composicion", sortable: true },
    { name: "Unidad", field: "unidad", sortable: true },
    { name: "Ancho", field: "ancho", sortable: true },
    { name: "Peso", field: "peso", sortable: true },
    { name: "Tipo de Tela", field: "tiposTelas", sortable: true },
    { name: "Coleccion", field: "coleccion", sortable: true },
    { name: "Venta", field: "venta", sortable: true },
    { name: "Rollos", field: "rollos", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Metros", field: "metros2", sortable: true },
    { name: "Costo Mt", field: "costoMt", sortable: true },
    { name: "Costo Total", field: "costoTotal", sortable: true },
    { name: "Venta Total", field: "ventaTotal", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idColores"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        idColores: item.idColores,
        cantidad: 0,
        metros2: 0,
        rollos: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].metros2 += item.metros2;
      groups[val]._id = item._id;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].nombre = item.nombre;
      groups[val].idArticulo = item.idArticulo;
      groups[val].composicion = item.composicion;
      groups[val].descripcion = item.descripcion;
      groups[val].unidad = item.unidad;
      groups[val].ancho = item.ancho;
      groups[val].peso = item.peso;
      groups[val].rendimiento = item.rendimiento;
      groups[val].idTiposTelas = item.idTiposTelas;
      groups[val].tiposTelas = item.tiposTelas;
      groups[val].idColeccion = item.idColeccion;
      groups[val].coleccion = item.coleccion;
      groups[val].costo = item.costo;
      groups[val].venta = item.venta;
      groups[val].costoTotal = item.costoTotal;
      groups[val].ventaTotal = item.ventaTotal;
      groups[val].observaciones = item.observaciones;
      groups[val].colores = item.colores;
      groups[val].idColores = item.idColores;
      groups[val].rollos += item.rollos;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      return groups;
    }, []);

    let computedComments = Object.values(agrupado);

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.ancho.toString().includes(search) ||
          comment.peso.toString().includes(search) ||
          comment.venta.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.ventaTotal.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) ||
          comment.rollos.toString().includes(search) ||
          comment.metros2.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      );
    }

    if (selectedTiposTelasFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposTelas.includes(selectedTiposTelasFiltro)
      );
    }
    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }
    if (selectedColor) {
      computedComments = computedComments.filter((e) =>
        e.idColores.includes(selectedColor)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedNombre)
      );
    }
    if (unidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(unidad)
      );
    }
    if (selectedComposicion) {
      computedComments = computedComments.filter((e) =>
        e.composicion.includes(selectedComposicion)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }

    let met = computedComments.filter((e) => e.unidad == "Metros");
    let cant = met.map((c) => parseFloat(c.cantidad));
    let TCant = cant.reduce((t, cantidad, index) => t + cantidad, 0);
    setTotalCantidadMetros(TCant);

    let kil = computedComments.filter((e) => e.unidad == "Kilos");
    let cantK = kil.map((c) => parseFloat(c.cantidad));
    let TKil = cantK.reduce((t, cantidad, index) => t + cantidad, 0);
    setTotalCantidadKilos(TKil);

    let cantM2 = computedComments.map((c) => parseFloat(c.metros2));
    let TM2 = cantM2.reduce((t, cantidad, index) => t + cantidad, 0);
    setTotalCantidadMetros2(TM2);

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "metros2" &&
      sorting.field != "ancho" &&
      sorting.field != "peso" &&
      sorting.field != "venta" &&
      sorting.field != "costo" &&
      sorting.field != "ventaTotal" &&
      sorting.field != "costoTotal" &&
      sorting.field != "rollos" &&
      sorting.field != "codigo" &&
      sorting.field != "costoMt"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "metros2" ||
        sorting.field == "ancho" ||
        sorting.field == "peso" ||
        sorting.field == "venta" ||
        sorting.field != "costo" ||
        sorting.field != "ventaTotal" ||
        sorting.field != "costoTotal" ||
        sorting.field != "rollos" ||
        sorting.field != "codigo" ||
        sorting.field != "costoMt")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "metros2" ||
        sorting.field == "ancho" ||
        sorting.field == "peso" ||
        sorting.field == "venta" ||
        sorting.field != "costo" ||
        sorting.field != "ventaTotal" ||
        sorting.field != "costoTotal" ||
        sorting.field != "rollos" ||
        sorting.field != "codigo" ||
        sorting.field != "costoMt")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedTiposTelasFiltro,
    selectedColeccion,
    selectedColor,
    selectedCodigo,
    selectedNombre,
    unidad,
    selectedComposicion,
    selectedProveedor,
  ]);



  let totalRollos = 0;
  let totalCosto = 0;
  let totalVenta = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.inventarios ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            

            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <h3 align="center">Inventario</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>{" "}
            <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigo}
                  onChange={(e) => {
                    setSelectedCodigo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosCodigos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idArticulo}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNombre}
                  onChange={(e) => {
                    setSelectedNombre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosNombre
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idArticulo}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColor}
                  onChange={(e) => {
                    setSelectedColor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedComposicion}
                  onChange={(e) => {
                    setSelectedComposicion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosComposicion
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a}>{a}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  placeholder="Unidad"
                  value={unidad}
                  required
                  onChange={(e) => {
                    setUnidad(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Metros">Metros</option>
                  <option value="Kilos">Kilos</option>
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedTiposTelasFiltro}
                  onChange={(e) => {
                    setSelectedTiposTelasFiltro(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {tipos_telas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                totalRollos = totalRollos + parseFloat(a.rollos);
                totalCosto = totalCosto + parseFloat(a.costoTotal);
                totalVenta = totalVenta + parseFloat(a.ventaTotal);

                {
                  return (
                    <tr>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.colores}</td>
                      <td>{a.composicion}</td>
                      <td>{a.unidad}</td>
                      <td>{a.ancho}</td>
                      <td>{a.peso}</td>
                      <td>{a.tiposTelas} </td>
                      <td>{a.coleccion} </td>
                      {/* <td>
                        {"$" + new Intl.NumberFormat("en-US").format(a.costo)}
                      </td> */}
                      <td>
                        {"$" + new Intl.NumberFormat("en-US").format(a.venta)}
                      </td>
                      <td>{new Intl.NumberFormat("en-US").format(a.rollos)}</td>

                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.metros2)}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            (
                              parseFloat(a.costoTotal) / parseFloat(a.cantidad)
                            ).toFixed(2)
                          )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(a.costoTotal)}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(a.ventaTotal)}
                      </td>
                      <td>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalRollos)}
                </td>
                <td className="negrita">
                  Metros: {new Intl.NumberFormat("en-US").format(totalCantidadMetros)}
                  
                </td>
                <td className="negrita">Kilos: {new Intl.NumberFormat("en-US").format(totalCantidadKilos)}</td>
                <td className="negrita">Total Metros: {new Intl.NumberFormat("en-US").format(totalCantidadMetros2)}</td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(totalCosto)}
                </td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(totalVenta)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

      {loader}
    </>
  );
}

export default ListadoInventariosGlobal;
